@import 'ui/variables.module.scss';

.card {
  max-width: 813px;
  margin: 33px auto 0 auto;
}

.items {
  padding: 0 10px 20px 10px;
}

.flex {
  flex-direction: column;
  align-items: flex-start;
}

.lowMargin {
  margin-bottom: 2px;
}

.ctnTextArea {
  flex-direction: column;

  .textArea {
    display: block;
    width: 100%;
  }
}

.exercisesEffectiveness {
  flex-direction: column;
  align-items: flex-start;
  label {
    align-items: baseline;
    text-align: left;
    height: fit-content;
    margin-bottom: 8px;
  }
}

.radioGroupWithValues {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 10px;
}

.labelInfo {
  color: #FA8C16;
  font-style: italic;
  font-size: 12px;
}

.labelHeight {
  min-height: 50px;
  text-align: left;
}

.noSpacing {
  margin: 0;
  padding: 0;
}

.customLabel {
  display: inline;
  .complementaryInformations {
    margin-left: calc(16px + 8px); // Antd radio size + Antd padding label
    font-size: 0.7rem;
    display: block;
  }

  @include under-desktop {
    .complementaryInformations {
      margin-left: 8px;
      display: inline;
    }
  }
}

.checkboxGroup {
  display: flex;
  flex-direction: column; /* Aligne les checkboxes verticalement */
}

.checkbox{
  margin-left: 8px;
}