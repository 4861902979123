//
// COLORS
//
$grey1: #ffffff;
$grey3: #f5f5f5;
$grey4: #e8e8e8;
$grey6: #bfbfbf;
$grey8: #595959;
$white: $grey1;
$grey: #302e2e;
$black: #000000;
$blue5: #40a9ff;
$blue9: #003a8c;

//
// VARIABLES
//
// 130px : padding general
// 13px: unité de spacing generale

//
// DIMENSIONS
//
$desktop-max-width: 1440px;

//
// MIXINS
//
@mixin desktop-max-width {
  max-width: $desktop-max-width;
}

@mixin elevation2 {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
}

/*--------------------------------------------------------------
- Media queries: Base mixins for responsive breakpoints
--------------------------------------------------------------*/
$mobile-width: 320px;
$tablet-width: 768px;
$desktop-width: 1024px;
$large-desktop-width: 1200px;

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin under-desktop {
  @media (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}
@mixin under-large-desktop {
  @media (max-width: #{$large-desktop-width - 1px}) {
    @content;
  }
}

@mixin landscape {
  @media (orientation: landscape) {
    @content;
  }
}

@mixin portrait {
  @media (orientation: portrait) {
    @content;
  }
}
