.disabledRow {
  opacity: 0.3;
  pointer-events: none;
  text-decoration: line-through;
}

.marginTop10 {
  margin-top: 10px;
}

.columnWrapper {
  display: flex;
  flex-direction: column;
}
