.submit {
  margin: 0 auto;
  display: block;
}

.reset {
  margin: 10px auto;
  display: block;
}

.divider {
  width: 1px;
  height: 100%;
  margin: 0 auto;
}

.selectSearchType {
  margin-bottom: 5px;
}

.selectSearchTypeArrow {
  color: #000;
  font-size: 16px !important;
  font-weight: bolder;
  margin-top: -4px;
}
